export default {
  inject: {
    companyPhone: {
      default: Window.companyPhone
    },
    companyPhoneHref: {
      default: 'tel:'+Window.companyPhone
    },
    companyEmail: {
      default: Window.companyEmail
    }
  }
}
